exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actividades-02-js": () => import("./../../../src/pages/actividades02.js" /* webpackChunkName: "component---src-pages-actividades-02-js" */),
  "component---src-pages-actividades-js": () => import("./../../../src/pages/actividades.js" /* webpackChunkName: "component---src-pages-actividades-js" */),
  "component---src-pages-articulos-js": () => import("./../../../src/pages/articulos.js" /* webpackChunkName: "component---src-pages-articulos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotres-js": () => import("./../../../src/pages/nosotres.js" /* webpackChunkName: "component---src-pages-nosotres-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-proyectos-02-js": () => import("./../../../src/pages/proyectos02.js" /* webpackChunkName: "component---src-pages-proyectos-02-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-publicaciones-02-js": () => import("./../../../src/pages/publicaciones02.js" /* webpackChunkName: "component---src-pages-publicaciones-02-js" */),
  "component---src-pages-publicaciones-js": () => import("./../../../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-articulo-a-js": () => import("./../../../src/templates/articuloA.js" /* webpackChunkName: "component---src-templates-articulo-a-js" */),
  "component---src-templates-proyecto-b-js": () => import("./../../../src/templates/proyectoB.js" /* webpackChunkName: "component---src-templates-proyecto-b-js" */)
}

